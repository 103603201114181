import Vue from "vue";
import Router from "vue-router";
import VueMeta from 'vue-meta';

Vue.use(Router);
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

export default new Router({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'Trang chủ',
        component: () =>
            import ("../views/home/HomeView.vue")
    }, {
        path: '/user',
        name: 'Quản trị viên',
        component: () =>
            import ('../views/user/UserView.vue')
    }, {
        path: '/student',
        name: 'Học viên',
        component: () =>
            import ('../views/student/StudentView.vue')
    }, {
        path: '/student/detail/:studentId',
        name: 'Chi tiết học viên ',
        component: () =>
            import ('../views/student/StudentDetailView.vue')
    }, {
        path: '/teacher',
        name: 'Giáo viên',
        component: () =>
            import ('../views/teacher/TeacherView.vue')
    }, {
        path: '/teacher/detail/:teacherId',
        name: 'Chi tiết giáo viên ',
        component: () =>
            import ('../views/teacher/TeacherDetailView.vue')
    },  {
        path: '/stadium',
        name: 'Sân vận động',
        component: () =>
            import ('../views/stadium/StadiumView.vue')
    }, {
        path: '*',
        name: "Không tìm thấy",
        component: () =>
            import ("../views/NotFound.vue")
    }]
});
export function getPagings(items) {
    const results = [];
    const totalPage = items.totalPage;

    var first = items.page - 1;
    var last = items.page + 1;
    if (first < 1) {
        last += items.page - first;
        first += items.page - first;
    }
    if (last > totalPage) {
        if (first > last - totalPage) first -= last - totalPage;
        last -= last - totalPage;
    }
    if (first > 1) results.push('..');
    for (let i = first; i <= last; i++) {
        results.push(i);
    }
    if (last < totalPage) results.push('..');
    return results;
}

export function listToTree(list) {
    var map = {},
        node, roots = [],
        i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_id.toString() !== "0") {
            if (list[map[node.parent_id]]) list[map[node.parent_id]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export function setTreeData(treeData, parent_id, result) {
    if (treeData && treeData.length > 0)
        for (let i = 0; i < treeData.length; i++) {
            const item = treeData[i];
            if (item.id == parent_id) {
                item.children = result;
                return true;
            }
            const isSet = setTreeData(item.children, parent_id, result);
            if (isSet) return true;
        }
    return false;
}

export function emptyObject(object) {
    return Object.keys(object).length <= 0;
}

String.prototype.replaceAll = function(stringToFind, stringToReplace) {
    var temp = this;
    var index = temp.indexOf(stringToFind);
    while (index != -1) {
        temp = temp.replace(stringToFind, stringToReplace);
        index = temp.indexOf(stringToFind);
    }
    return temp;
};

export function nonAccentVietnamese(str) {
    if (str) {
        str = str.toLowerCase();
        //     We can also use this instead of from line 11 to line 17
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        // Some system encode vietnamese combining accent as individual utf-8 characters
        str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng 
        str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
        str = str.replace(/[^a-zA-Z0-9 ]/g, "");
        str = str.replaceAll(" ", "-");
        str = str.replaceAll("--", "-");
        return str;
    }
    return "";
}

export function checkValid(bvToast, items) {
    if (items) {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            var cond = item.condition ? !item.condition : (item.value ? !item.value.toString().trim() : true);
            if (cond) {
                if (item.message) bvToast.toast(item.message, { title: 'Thông báo', variant: 'danger', solid: true, autoHideDelay: 3000 });
                if (item.id) document.getElementById(item.id).focus();
                if (item.element) item.element.focus();
                return false;
            }
        }
    }
    return true;
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
    if (phone) {
        var phoneno1 = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var phoneno2 = /^\+?([0-9]{4})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return phone.match(phoneno1) || phone.match(phoneno2);
    }
    return false;
}

export function formatPhone(phone) {
    if (validatePhone(phone)) {
        var cleaned = ('' + phone).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{4}|\d{3})(\d{3})(\d{4})$/);
        if (match) return (match[1].length == 3 ? '' : '') + match[1] + ' ' + match[2] + ' ' + match[3];
    }
    return "";
}

export function notifyToast(toast, message, variant) {
    toast.toast(message, {
        title: 'Thông báo',
        variant: variant,
        autoHideDelay: 3000,
        solid: true
    })
}

export function confirmSwal(swal, nameTitle, nameItem, isDelete = true) {
    return new Promise((resolve) => {
        const title = isDelete ? ('Xóa ' + nameTitle) : nameTitle;
        const text = isDelete ? (`Bạn chắc chắn xóa ${nameTitle}` + (nameItem ? ` ${nameItem}?` : ' ?')) : nameItem;
        swal({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#17a2b8",
            confirmButtonText: isDelete ? 'Xóa' : 'Đồng ý',
            cancelButtonText: 'Hủy',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async(result) => {
            resolve(result.value);
        })
    });
}

export function formatNumber(value) {
    try {
        value = parseFloat(value);
        return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    } catch (error) {
        console.log("error: ", error);
    }
    return 0;
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function openWinCenter(url, w = 500, h = 700) {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(url, "windowloginmt", `scrollbars=yes,width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`)
    if (window.focus) newWindow.focus();
    return newWindow;
}

export function getParamFromUrl(url_string, name) {
    var url = new URL(url_string);
    var result = url.searchParams.get(name);
    return decodeURIComponent(result);
}

export function loginWithPopup(goToUrl, redirectUri, callBack) {
    const newWindow = openWinCenter(goToUrl);
    const timeTick = 200; //ms
    var timeExpire = 60 * 10 * 1000; //10'
    const pollTimer = setInterval(() => {
        try {
            if (newWindow.document.URL.indexOf(redirectUri) >= 0) {
                const code = getParamFromUrl(newWindow.document.URL, "code");
                callBack(code ? code : "");
                clearInterval(pollTimer);
                newWindow.close();
            }
        } catch (e) {
            timeExpire -= timeTick;
            if (timeExpire <= 0 || newWindow.closed) clearInterval(pollTimer);
        }
    }, timeTick);
}

export function setObjectValue(objTemplate, objValue) {
    for (var property in objTemplate) {
        var value = objValue[property];
        objTemplate[property] = value ? value : objTemplate[property];
    }
    return objTemplate;
}

export function getByPageOrder(paging, orders, limit) {
    if (!paging) paging = { page: 1, perPage: limit };
    const o = orders.map((e) => { return e.column; }).join();
    const t = orders.map((e) => { return e.type; }).join();
    return `page=${paging.page}&per_page=${paging.perPage}` + (o && t ? `&orders=${o}&order_types=${t}` : '');
}

export function getDate(value) {
    if (!value) return "";
    // const hrs = -(new Date().getTimezoneOffset() / 60);
    var date = new Date(value);
    // date.setHours(date.getHours() + hrs)
    // console.log("date", date, value.substring(0, 10));
    if (!(date instanceof Date && !isNaN(date))) {
        value = Date.parse(value.substring(0, 10), "yyyy-dd-MM");
        date = new Date(value);
    }
    return date;
}
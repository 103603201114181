<template>
    <div>
        <AdminView v-if="user" />
        <SignInView v-else />
        <!-- <b-modal centered hide-footer hide-header no-close-on-backdrop hide-backdrop id="loading-modal"> -->
        <!-- <div class="loading-modal"></div> -->
        <!-- <div>Loading</div> -->
        <!-- <i class="fas fa-2x fa-sync-alt fa-spin"></i> -->
        <!-- </b-modal> -->
        <div id="loading-modal" class="modal" style="background: rgb(128 128 128 / 20%); z-index: 20000;">
            <div class="modal-dialog" style="width: 100%;height: 100%;">
                <div class="loading-modal"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAdminAuthen } from "./common/storage";

// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
    data() {
        return {
            user: null,
        };
    },
    components: {
        SignInView: () => import("./views/SignInView.vue"),
        AdminView: () => import("./views/AdminView.vue"),
    },
    mounted() {
        this.user = getAdminAuthen();
        console.log("AdminView >> user", this.user)
    },
};
</script>

<style>
@import "./assets/css/all.min.css";
@import "./assets/css/adminlte.min.css";
@import "./assets/css/icheck-bootstrap.min.css";
@import "./assets/css/style-admin.css";
</style>
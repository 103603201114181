import { formatNumber, formatPhone, nonAccentVietnamese, notifyToast, confirmSwal, checkValid, emptyObject, setObjectValue, getDate } from './functions';
import { getAdminAuthen, getDistricts, getStadiums } from "./storage";
import config from './config';

export default {
    data: function() {
        return {
            appName: config.MAIN_NAME
        };
    },
    methods: {
        limitCharacter(str, value) {
            if (str.length > value) return `${str.substring(0,value)}...`;
            return str;
        },
        strLength(value) {
            if (value) return value.length;
            return 0;
        },
        formatNumber(value, curency) {
            if (typeof(value) != "undefined" && value != null && value.toString().trim() != "" && value >= 0)
                return formatNumber(value) + (curency ? ` ${config.CURRENCY_UNIT}` : "");
            return "";
        },
        formatPhone(value) {
            return formatPhone(value);
        },
        formatDateYMD(value) {
            const date = getDate(value);
            if (date) return this.$moment(date).format('YYYY-MM-DD');
            return "";
        },
        formatDate(value) {
            const date = getDate(value);
            if (date) return this.$moment(date).format(config.FORMAT_DATE);
            return "";
        },
        formatDateTime(value) {
            const date = getDate(value);
            if (date) return this.$moment(date).format(config.FORMAT_DATE_TIME);
            return "";
        },
        buildUrl(beforeId, value, afterId = null) {
            return `${beforeId}-${nonAccentVietnamese(value)}` + (afterId ? `-${afterId}` : "");
        },
        fullPathFile(value) {
            const user = getAdminAuthen();
            if (user && user.main_bucket && value)
                return `https://${user.main_bucket}.s3.ap-northeast-1.amazonaws.com/${value}`;
            return "";
        },
        isAdmin() {
            const user = getAdminAuthen();
            return user && (user.role == 1 || user.display == 0);
        },
        notifyToast(message, variant) {
            return notifyToast(this.$bvToast, message, variant);
        },
        confirmDelete(moduleName, objectName) {
            return confirmSwal(this.$swal, moduleName, objectName);
        },
        confirmSwal(moduleName, objectName) {
            return confirmSwal(this.$swal, moduleName, objectName, false);
        },
        checkValidForm(items) {
            return checkValid(this.$bvToast, items);
        },
        isEmptyObject(object) {
            return emptyObject(object);
        },
        setObjectValue(objTemplate, objValue) {
            return setObjectValue(objTemplate, objValue);
        },
        setTitle(value) {
            document.title = (value ? `${value} | ` : ``) + config.MAIN_NAME;
            const title = document.getElementById("page-title-content");
            if (title) title.innerHTML = `${value}`;
            // document.getElementById("breadcrumb-content").innerHTML = "";
            // document.getElementById("btn-back-content").innerHTML = "";
        },
        showModal(id) {
            this.$bvModal.show(id);
        },
        hideModal(id) {
            this.$bvModal.hide(id);
        },
        showLoading(value) {
            const loading = document.getElementById("loading-modal");
            if (!loading) return;
            if (value) loading.style.display = "block"; //this.showModal("loading-modal");
            else loading.style.display = "none"; // this.hideModal("loading-modal");
        },
        checkResponse(data, success, error = null) {
            if (data && data.error) {
                if (error) error(data.error);
                else this.notifyToast(data.error, 'danger');
            } else if (data) {
                // this.notifyToast('Success', 'success');
                success();
            } else {
                if (error) error();
                else this.notifyToast('Không thành công', 'danger');
            }
        },
        getTextOption(value, options, isFull = false) {
            if (value && options && options.length > 0) {
                const option = options.filter(o => { return o.value == value; });
                if (option && option.length > 0) return isFull ? option[0] : option[0].text;
            }
            return isFull ? null : "";
        },
        getDistrictBys(provinceId) {
            const districts = getDistricts();
            if (districts && districts.length > 0)
                return districts.filter(e => e.province_id == provinceId);
            return [];
        },
        getStadiumBys(filter = null) {
            const stadiums = getStadiums();
            if (stadiums && stadiums.length > 0) {
                if (filter && filter.ward) return stadiums.filter(e => e.ward_id == filter.ward);
                if (filter && filter.district) return stadiums.filter(e => e.district_id == filter.district);
                if (filter && filter.province) return stadiums.filter(e => e.province_id == filter.province);
                return stadiums;
            }
            return [];
        }
    },
}
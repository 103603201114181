const _get = (_key) => {
    return JSON.parse(window.localStorage.getItem(_key));
}
const _set = (_key, _data) => {
    window.localStorage.setItem(_key, JSON.stringify(_data));
}
const _remove = (_key) => {
    window.localStorage.removeItem(_key);
}

const ADMIN_USER_LOGIN_DATA = "ADMIN_USER_LOGIN_DATA";
export const getAdminAuthen = () => _get(ADMIN_USER_LOGIN_DATA);
export const saveAdminAuthen = (data) => _set(ADMIN_USER_LOGIN_DATA, data);
export const removeAdminAuthen = () => _remove(ADMIN_USER_LOGIN_DATA);

const ADMIN_USER_REMEMBER = "ADMIN_USER_REMEMBER";
export const getAdminRemember = () => _get(ADMIN_USER_REMEMBER);
export const saveAdminRemember = (data) => _set(ADMIN_USER_REMEMBER, data);
export const removeAdminRemember = () => _remove(ADMIN_USER_REMEMBER);

const ADMIN_PAGING_LIMIT = "ADMIN_PAGING_LIMIT";
export const getPagingLimit = () => _get(ADMIN_PAGING_LIMIT);
export const savePagingLimit = (data) => _set(ADMIN_PAGING_LIMIT, data);
export const removePagingLimit = () => _remove(ADMIN_PAGING_LIMIT);

const ADMIN_ADDRESS_PROVINCE = "ADMIN_ADDRESS_PROVINCE";
export const getProvinces = () => _get(ADMIN_ADDRESS_PROVINCE);
export const saveProvinces = (data) => _set(ADMIN_ADDRESS_PROVINCE, data);
export const removeProvinces = () => _remove(ADMIN_ADDRESS_PROVINCE);

const ADMIN_ADDRESS_DISTRICT = "ADMIN_ADDRESS_DISTRICT";
export const getDistricts = () => _get(ADMIN_ADDRESS_DISTRICT);
export const saveDistricts = (data) => _set(ADMIN_ADDRESS_DISTRICT, data);
export const removeDistricts = () => _remove(ADMIN_ADDRESS_DISTRICT);

const ADMIN_STADIUM = "ADMIN_STADIUM";
export const getStadiums = () => _get(ADMIN_STADIUM);
export const saveStadiums = (data) => _set(ADMIN_STADIUM, data);
export const removeStadiums = () => _remove(ADMIN_STADIUM);
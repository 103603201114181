import Vue from 'vue'
import App from './App.vue'
import router from './router'
import mixin from './common/mixin'
import VueMoment from 'vue-moment'
import VueSweetalert2 from 'vue-sweetalert2'
import { ToastPlugin, OverlayPlugin, AvatarPlugin, FormSelectPlugin, ModalPlugin, FormDatepickerPlugin, FormFilePlugin, ImagePlugin } from 'bootstrap-vue'

Vue.config.productionTip = false

Vue.mixin(mixin)
Vue.use(VueMoment)
Vue.use(VueSweetalert2)
Vue.use(ToastPlugin)
Vue.use(OverlayPlugin)
Vue.use(AvatarPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ModalPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormFilePlugin)
Vue.use(ImagePlugin)

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')